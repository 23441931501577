<template>
  <div class="tablecontain">
    <div class="searchcontain">
      <div class="left">
        <div class="maintext">起始周:</div>
        <el-select class="elinput" v-model="queryParams.start_week" placeholder="请选择" size="medium ">
          <el-option v-for="item in weeknum" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>

        <div style="margin-left: 60px" class="maintext">截止周:</div>
        <el-select class="elinput" v-model="queryParams.end_week" placeholder="请选择" size="medium ">
          <el-option v-for="item in weeknum" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <div class="bt restbt" @click="resetbt">重置</div>
        <div class="bt searchbt" @click="searchbt">查询</div>
        <div class="bt searchbt_1" @click="reservebt">预约</div>
      </div>
    </div>
    <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }" :row-style="{
  'font-size': '15px',
  color: '#222222',
  'font-weight': '400',
}" @selection-change="handleSelectionChange">
      >
      <el-table-column type="selection" width="55" :selectable="selectEnable">
      </el-table-column>
      <el-table-column prop="week_number" label="周次"> </el-table-column>
      <el-table-column prop="day" label="星期">
        <template slot-scope="scope">
          {{ scope.row.day | dayFilter }}{{ scope.row.date_time | dataformat }}
        </template>
      </el-table-column>
      <el-table-column prop="lesson_name" label="节次"> </el-table-column>
      <el-table-column prop="total_book_count" label="已预约人数">
      </el-table-column>
      <el-table-column prop="experiment_name" label="项目名称">
        <template slot-scope="scope">
          <el-input class="elinput" v-if="scope.row.booked == 0" v-model="scope.row.experiment_name" placeholder="请输入内容"
            @change="experimentName(scope.row.experiment_name, scope.row)">
          </el-input>
          <div v-if="scope.row.booked == 1">{{ scope.row.experiment_name }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :page-size="10" :current-page="queryParams.page"
        :total="total" @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getlessonlist, openexperimentbook } from "@/api/openexperimentbook";
const dayMap = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "日",
};
export default {
  name: "Home",

  components: {},
  props: {
    closebt: {
      type: Function,
    },
  },
  filters: {
    dataformat(value) {
      // console.log("日期格式化", value);
      if (!value) return "";
      let time = dayjs.unix(value).format("YYYY.MM.DD");
      return "(" + time + ")";
    },
    dayFilter(value) {
      return dayMap[value];
    },
  },
  data() {
    return {
      type: "",
      total: 0,
      tableData: [],
      weeknum: "",
      multipleSelection: '',
      experiment_name: '',
      queryParams: {
        open_experiment_id: '',
        start_week: '',
        end_week: '',
        page: 1,
        per_page: 10
      }
    };
  },
  methods: {
    inidata(item, weeknum, type) {
      this.weeknum = parseInt(weeknum);
      this.queryParams.open_experiment_id = item.id;
      this.type = type;
      this.getlessonlist();
    },
    searchbt() {
      this.queryParams.page = 1
      this.getlessonlist();
    },
    resetbt() {
      this.queryParams.start_week = ''
      this.queryParams.end_week = ''
      this.queryParams.page = 1
      this.queryParams.per_page = 10
      this.getlessonlist();
    },
    changePage(val) {
      this.queryParams.page = val
      this.getlessonlist();
    },
    reservebt() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          let el = this.multipleSelection[i]
          if (el.experiment_name == '' || el.experiment_name == null ) {
            this.$message({
              message: "请输入项目名称",
              type: "warning",
            });
            return false
          }
        }
        
        this.openexperimentbook({
          open_experiment_id: this.queryParams.open_experiment_id,
          lesson_arr: JSON.stringify(this.multipleSelection),
        });
      } else {
        this.$message({
          message: "至少选择一项！",
          type: "warning",
        });
      }
    },
    experimentName(experiment_name, row) {
      this.experiment_name = experiment_name
      this.multipleSelection.forEach(item => {
        if (item.course_lib_weekly_lesson_id == row.course_lib_weekly_lesson_id) {
          item.experiment_name = this.experiment_name
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      if (val && val.length > 0) {
        val.forEach((item) => {
          if (item.booked == 0) {
            this.multipleSelection.push({
              course_lib_weekly_lesson_id: item.course_lib_weekly_lesson_id,
              experiment_name: item.experiment_name,
            });
          }
        });
      }
    },
    selectEnable(row) { // 复选框可选情况
      if (row.booked == 1) { // 禁用
        return false;
      } else {
        return true;
      }
    },
    getlessonlist() {
      getlessonlist(this.queryParams)
        .then((response) => {
          if (response.code === 0) {
            if (response.data.data && response.data.data.length > 0) {
              this.tableData = response.data.data;
              this.total = response.data.total
              this.tableData.forEach(item => {
                if (item.booked == 1) {
                  this.$refs.singleTable.toggleRowSelection(item, true)
                }
              })
            }
          }
        })
        .catch((error) => { });
    },
    openexperimentbook(params) {
      openexperimentbook(params)
        .then((response) => {
          if (response.code === 0) {
            this.$message({
              message: "预约成功！",
              type: "success",
            });
            this.closebt();
            // this.multipleSelection = []
            // this.getlessonlist();
          }
        })
        .catch((error) => { });
    },
  },
};
</script>
<style scoped lang="scss">
.tablecontain {
  .searchcontain {
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .maintext {
        margin-right: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #888888;
      }

      .elinput {
        width: 200px;

        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
      }

      .restbt {
        margin-right: 10px;
        color: #3d84ff;
      }

      .searchbt {
        background: #3d84ff;
        color: white;
      }

      .searchbt_1 {
        background: #3d84ff;
        color: white;
        margin-left: 10px;
      }
    }
  }

  .reserve {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .reservebt {
      margin-top: 40px;
      margin-right: 10px;
      width: 64px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #3d84ff;
      color: white;
      border: 1px solid #3d84ff;
      border-radius: 2px;
      user-select: none;
      cursor: pointer;

      &:active {
        background: #3d84ff;
      }
    }
  }

  .Table {
    margin-top: 10px;
    padding: 10px;

    .elinput {
      width: 120px;
    }

    .opreatbt {
      border: none;
      background: none;
      margin: 0px;
      padding: 0px;
      color: #3d84ff;
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }
}
</style>
